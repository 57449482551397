let self = {
    zh:{
        reg5:"PH",
        reg4:"ORP",
        reg6:"余氯",
        reg3:"水温",
        reg7:"浊度",
        reg8:"尿素",
        reg1001:"溶解氧",
        reg1002:"电导率",
        reg1003:"流量",
        reg1004:"流速",
        reg1005:"换水率",
        reg1006:"浸脚池余氯",

        noticeTitle:"公告栏",
        stNormal:"适宜",
        stLow:"偏低",
        stHigh:"超标",

        date:"日期",
        time:"时间",
        dateFormat:"YYYY年MM月DD日",
        timeFormat:"HH:mm:ss"
    },
    en:{
        reg5:"PH",
        reg4:"ORP",
        reg6:"Residual chlorine",
        reg3:"Water Temperature",
        reg7:"NTU",
        reg8:"Urea",
        reg1001:"DO",
        reg1002:"EC",
        reg1003:"Flow",
        reg1004:"velocity of flow",
        reg1005:"rate of water exchange",
        reg1006:"Residual chlorine of foot bath",

        noticeTitle:"Notice",
        stNormal:"balanced",
        stLow:"not balanced",
        stHigh:"not balanced",

        date:"Date",
        time:"Clock",
        dateFormat:"DD/MM/YYYY",
        timeFormat:"HH:mm:ss"
    }
}
export default self
